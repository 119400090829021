import { render, staticRenderFns } from "./ModalPhoto.vue?vue&type=template&id=2ddb6910&scoped=true&"
import script from "./ModalPhoto.vue?vue&type=script&lang=js&"
export * from "./ModalPhoto.vue?vue&type=script&lang=js&"
import style0 from "./ModalPhoto.vue?vue&type=style&index=0&id=2ddb6910&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ddb6910",
  null
  
)

export default component.exports